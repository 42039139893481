@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap');

html {
  height: 100%;
  background: #F7F7FD;
}

body > #root > #main {
  min-height: 100vh;
}

.leaflet-container {
  width: 200vh;
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Bai Jamjuree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

input, button, span, ul, li {
  font-family: 'Bai Jamjuree', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
